exports.mobileBreakpoint = 320
exports.tabletBreakpoint = 768
exports.computerBreakpoint = 992
exports.largeMonitorBreakpoint = 1200
exports.widescreenMonitorBreakpoint = 1920
exports.HOSTNAME = 'https://localhost:8000/'
exports.LIVE_URL = 'https://www.tischler-reisen.de'
exports.USERCENTRICS_ID = '5gd5mnQUH'

exports.dateFormat = 'DD.MM.YYYY'

exports.colorRed = '#A3967F'
exports.colorDarkRed = '#A3967F'
exports.colorBlack = '#000000'
exports.colorWhite = '#ffffff'
exports.colorLightGray = '#ebebeb'
exports.colorGray = '#CAC8C7'
exports.colorGraySecond = '#cccccc'
exports.colorDarkGray = '#4d4d4d'

exports.links = {
  // Country regions without slug
  Asien: { de: 'asien' },
  'Indischer Ozean': { de: 'indischer-ozean' },
  'Orient & Marokko': { de: 'orient-and-marokko' },
  'Indischer Subkontinent': { de: 'indischer-subkontinent' },
  countries: { de: 'laender' },
  // place url parts
  all: { de: 'alles' },
  destinations: { de: 'reiseziele' },
  'country-regions': { de: 'country-regions' },
  regions: { de: 'regionen' },
  locations: { de: 'orte' },
  // service groups
  tours: { de: 'rundreisen' },
  hotels: { de: 'hotels' },
  trips: { de: 'ausfluge' },
  'all-services': { de: 'alle-dienstleistungen' },
  dossier: { de: 'reisevorschlag' },
  // WP-generated single pages
  entry: { de: 'einreisebestimmungen' },
  team: { de: 'team' },
  catalog: { de: 'kataloge' },
  wishlist: { de: 'meine-reise' },
  contact: { de: 'kontakt' },
  newsletter: { de: 'newsletter' },
  'reisebuero-finder': { de: 'reisebuero-finder' },
  offices: { de: 'offices' },
  // internal pages
  '404': { de: '404' }
}
